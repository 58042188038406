import { default as changezP8715OgRVMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/account/change.vue?macro=true";
import { default as forgetrDzAM8KcH1Meta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/account/forget.vue?macro=true";
import { default as categoryEWgwsXFQ6IMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/category.vue?macro=true";
import { default as index7ztLy7vsJTMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/credits/index.vue?macro=true";
import { default as thanksK8jtv3uOhgMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/credits/thanks.vue?macro=true";
import { default as calc_resulteTGk2CmbuXMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/expression/calc_result.vue?macro=true";
import { default as indexOOib0mYY9jMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/expression/index.vue?macro=true";
import { default as index2AlYhZIQdlMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/index.vue?macro=true";
import { default as login024E9aAufiMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/login.vue?macro=true";
import { default as myPXccTDSiS7Meta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/my.vue?macro=true";
import { default as calc_resultVDCHuNDuTZMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/num/calc_result.vue?macro=true";
import { default as detailDBz4BdiSiQMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/num/detail.vue?macro=true";
import { default as indexqZJOxqlXLjMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/num/index.vue?macro=true";
import { default as calc_resultOwcXOKlisLMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/palm/calc_result.vue?macro=true";
import { default as indexPFRoU1jtMEMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/palm/index.vue?macro=true";
import { default as calc_resultPi8mirJLbZMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/review/calc_result.vue?macro=true";
import { default as indexEZ8d2BviIWMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/review/index.vue?macro=true";
import { default as calc_result8Duc8rv2Z4Meta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/tq/calc_result.vue?macro=true";
import { default as indexpKJ0Sx3W2GMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/tq/index.vue?macro=true";
import { default as calc_resultNEovkCLkk0Meta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/wa/calc_result.vue?macro=true";
import { default as detailVaU5zE0hXxMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/wa/detail.vue?macro=true";
import { default as indexUvDb2BxkhUMeta } from "/Users/kawa/Desktop/code/zoey/fortune-telling/pages/wa/index.vue?macro=true";
export default [
  {
    name: changezP8715OgRVMeta?.name ?? "account-change",
    path: changezP8715OgRVMeta?.path ?? "/account/change",
    meta: changezP8715OgRVMeta || {},
    alias: changezP8715OgRVMeta?.alias || [],
    redirect: changezP8715OgRVMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/account/change.vue").then(m => m.default || m)
  },
  {
    name: forgetrDzAM8KcH1Meta?.name ?? "account-forget",
    path: forgetrDzAM8KcH1Meta?.path ?? "/account/forget",
    meta: forgetrDzAM8KcH1Meta || {},
    alias: forgetrDzAM8KcH1Meta?.alias || [],
    redirect: forgetrDzAM8KcH1Meta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/account/forget.vue").then(m => m.default || m)
  },
  {
    name: categoryEWgwsXFQ6IMeta?.name ?? "category",
    path: categoryEWgwsXFQ6IMeta?.path ?? "/category",
    meta: categoryEWgwsXFQ6IMeta || {},
    alias: categoryEWgwsXFQ6IMeta?.alias || [],
    redirect: categoryEWgwsXFQ6IMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/category.vue").then(m => m.default || m)
  },
  {
    name: index7ztLy7vsJTMeta?.name ?? "credits",
    path: index7ztLy7vsJTMeta?.path ?? "/credits",
    meta: index7ztLy7vsJTMeta || {},
    alias: index7ztLy7vsJTMeta?.alias || [],
    redirect: index7ztLy7vsJTMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/credits/index.vue").then(m => m.default || m)
  },
  {
    name: thanksK8jtv3uOhgMeta?.name ?? "credits-thanks",
    path: thanksK8jtv3uOhgMeta?.path ?? "/credits/thanks",
    meta: thanksK8jtv3uOhgMeta || {},
    alias: thanksK8jtv3uOhgMeta?.alias || [],
    redirect: thanksK8jtv3uOhgMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/credits/thanks.vue").then(m => m.default || m)
  },
  {
    name: calc_resulteTGk2CmbuXMeta?.name ?? "expression-calc_result",
    path: calc_resulteTGk2CmbuXMeta?.path ?? "/expression/calc_result",
    meta: calc_resulteTGk2CmbuXMeta || {},
    alias: calc_resulteTGk2CmbuXMeta?.alias || [],
    redirect: calc_resulteTGk2CmbuXMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/expression/calc_result.vue").then(m => m.default || m)
  },
  {
    name: indexOOib0mYY9jMeta?.name ?? "expression",
    path: indexOOib0mYY9jMeta?.path ?? "/expression",
    meta: indexOOib0mYY9jMeta || {},
    alias: indexOOib0mYY9jMeta?.alias || [],
    redirect: indexOOib0mYY9jMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/expression/index.vue").then(m => m.default || m)
  },
  {
    name: index2AlYhZIQdlMeta?.name ?? "index",
    path: index2AlYhZIQdlMeta?.path ?? "/",
    meta: index2AlYhZIQdlMeta || {},
    alias: index2AlYhZIQdlMeta?.alias || [],
    redirect: index2AlYhZIQdlMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/index.vue").then(m => m.default || m)
  },
  {
    name: login024E9aAufiMeta?.name ?? "login",
    path: login024E9aAufiMeta?.path ?? "/login",
    meta: login024E9aAufiMeta || {},
    alias: login024E9aAufiMeta?.alias || [],
    redirect: login024E9aAufiMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/login.vue").then(m => m.default || m)
  },
  {
    name: myPXccTDSiS7Meta?.name ?? "my",
    path: myPXccTDSiS7Meta?.path ?? "/my",
    meta: myPXccTDSiS7Meta || {},
    alias: myPXccTDSiS7Meta?.alias || [],
    redirect: myPXccTDSiS7Meta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/my.vue").then(m => m.default || m)
  },
  {
    name: calc_resultVDCHuNDuTZMeta?.name ?? "num-calc_result",
    path: calc_resultVDCHuNDuTZMeta?.path ?? "/num/calc_result",
    meta: calc_resultVDCHuNDuTZMeta || {},
    alias: calc_resultVDCHuNDuTZMeta?.alias || [],
    redirect: calc_resultVDCHuNDuTZMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/num/calc_result.vue").then(m => m.default || m)
  },
  {
    name: detailDBz4BdiSiQMeta?.name ?? "num-detail",
    path: detailDBz4BdiSiQMeta?.path ?? "/num/detail",
    meta: detailDBz4BdiSiQMeta || {},
    alias: detailDBz4BdiSiQMeta?.alias || [],
    redirect: detailDBz4BdiSiQMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/num/detail.vue").then(m => m.default || m)
  },
  {
    name: indexqZJOxqlXLjMeta?.name ?? "num",
    path: indexqZJOxqlXLjMeta?.path ?? "/num",
    meta: indexqZJOxqlXLjMeta || {},
    alias: indexqZJOxqlXLjMeta?.alias || [],
    redirect: indexqZJOxqlXLjMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/num/index.vue").then(m => m.default || m)
  },
  {
    name: calc_resultOwcXOKlisLMeta?.name ?? "palm-calc_result",
    path: calc_resultOwcXOKlisLMeta?.path ?? "/palm/calc_result",
    meta: calc_resultOwcXOKlisLMeta || {},
    alias: calc_resultOwcXOKlisLMeta?.alias || [],
    redirect: calc_resultOwcXOKlisLMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/palm/calc_result.vue").then(m => m.default || m)
  },
  {
    name: indexPFRoU1jtMEMeta?.name ?? "palm",
    path: indexPFRoU1jtMEMeta?.path ?? "/palm",
    meta: indexPFRoU1jtMEMeta || {},
    alias: indexPFRoU1jtMEMeta?.alias || [],
    redirect: indexPFRoU1jtMEMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/palm/index.vue").then(m => m.default || m)
  },
  {
    name: calc_resultPi8mirJLbZMeta?.name ?? "review-calc_result",
    path: calc_resultPi8mirJLbZMeta?.path ?? "/review/calc_result",
    meta: calc_resultPi8mirJLbZMeta || {},
    alias: calc_resultPi8mirJLbZMeta?.alias || [],
    redirect: calc_resultPi8mirJLbZMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/review/calc_result.vue").then(m => m.default || m)
  },
  {
    name: indexEZ8d2BviIWMeta?.name ?? "review",
    path: indexEZ8d2BviIWMeta?.path ?? "/review",
    meta: indexEZ8d2BviIWMeta || {},
    alias: indexEZ8d2BviIWMeta?.alias || [],
    redirect: indexEZ8d2BviIWMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/review/index.vue").then(m => m.default || m)
  },
  {
    name: calc_result8Duc8rv2Z4Meta?.name ?? "tq-calc_result",
    path: calc_result8Duc8rv2Z4Meta?.path ?? "/tq/calc_result",
    meta: calc_result8Duc8rv2Z4Meta || {},
    alias: calc_result8Duc8rv2Z4Meta?.alias || [],
    redirect: calc_result8Duc8rv2Z4Meta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/tq/calc_result.vue").then(m => m.default || m)
  },
  {
    name: indexpKJ0Sx3W2GMeta?.name ?? "tq",
    path: indexpKJ0Sx3W2GMeta?.path ?? "/tq",
    meta: indexpKJ0Sx3W2GMeta || {},
    alias: indexpKJ0Sx3W2GMeta?.alias || [],
    redirect: indexpKJ0Sx3W2GMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/tq/index.vue").then(m => m.default || m)
  },
  {
    name: calc_resultNEovkCLkk0Meta?.name ?? "wa-calc_result",
    path: calc_resultNEovkCLkk0Meta?.path ?? "/wa/calc_result",
    meta: calc_resultNEovkCLkk0Meta || {},
    alias: calc_resultNEovkCLkk0Meta?.alias || [],
    redirect: calc_resultNEovkCLkk0Meta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/wa/calc_result.vue").then(m => m.default || m)
  },
  {
    name: detailVaU5zE0hXxMeta?.name ?? "wa-detail",
    path: detailVaU5zE0hXxMeta?.path ?? "/wa/detail",
    meta: detailVaU5zE0hXxMeta || {},
    alias: detailVaU5zE0hXxMeta?.alias || [],
    redirect: detailVaU5zE0hXxMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/wa/detail.vue").then(m => m.default || m)
  },
  {
    name: indexUvDb2BxkhUMeta?.name ?? "wa",
    path: indexUvDb2BxkhUMeta?.path ?? "/wa",
    meta: indexUvDb2BxkhUMeta || {},
    alias: indexUvDb2BxkhUMeta?.alias || [],
    redirect: indexUvDb2BxkhUMeta?.redirect || undefined,
    component: () => import("/Users/kawa/Desktop/code/zoey/fortune-telling/pages/wa/index.vue").then(m => m.default || m)
  }
]