export default defineNuxtRouteMiddleware((to, from) => {
    if(process.client){
        if(!(to.path === '/login'||to.path === '/'||to.path === '/account/forget'||to.path === '/account/change')){
            const store = useAppStore();
            const isAuthed = useCookie('isAuthed')
            if(!store.auth.isAuth||isAuthed.value!=1){
                return navigateTo('/login')
            }
       }
   }
})