import revive_payload_client_4sVQNw7RlN from "/Users/kawa/Desktop/code/zoey/fortune-telling/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/Users/kawa/Desktop/code/zoey/fortune-telling/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/Users/kawa/Desktop/code/zoey/fortune-telling/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import plugin_vue3_A0OWXRrUgq from "/Users/kawa/Desktop/code/zoey/fortune-telling/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/kawa/Desktop/code/zoey/fortune-telling/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/Users/kawa/Desktop/code/zoey/fortune-telling/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/Users/kawa/Desktop/code/zoey/fortune-telling/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_1UohGbtF8v from "/Users/kawa/Desktop/code/zoey/fortune-telling/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import recordrtc_client_1palqnogXK from "/Users/kawa/Desktop/code/zoey/fortune-telling/plugins/recordrtc.client.js";
import vuetify_7h9QAQEssH from "/Users/kawa/Desktop/code/zoey/fortune-telling/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  plugin_1UohGbtF8v,
  recordrtc_client_1palqnogXK,
  vuetify_7h9QAQEssH
]